.fill {
    stroke-dasharray: 15000;
    stroke-dashoffset: 15000;
    stroke-width: 10px;
    animation: fill 5s ease forwards;
  }
  
  .logoPathBig {
    stroke-dasharray: 15000;
    stroke-dashoffset: 15000;
    stroke-width: 10px;
    animation: logo-animation-big 3s ease forwards;
  }
  
  .logoPathSmall {
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    stroke-width: 5px;
    animation: logo-animation-small 3s ease forwards;
    animation-delay: 1s;
    -webkit-animation: logo-animation-small 3s ease forwards;
}
  .logoPathSmallFill {
    stroke-dasharray: 2000;
    stroke-width: 5px;
    stroke-dashoffset: 2000;
    animation: logo-animation-small-fill 3s ease forwards;
    animation-delay: 1s;
  }
  
  .logoPathMedium {
    stroke-dasharray: 30000;
    stroke-dashoffset: 30000;
    stroke-width: 5px;
    animation: logo-animation-medium 5s ease forwards;
    animation-delay: 1s;
  }
  
  @keyframes logo-animation-big {
    from {
      fill: transparent;
    }
    to {
      stroke-dashoffset: 0;
      fill: #665a4f;
    }
  }
  
  @keyframes logo-animation-small {
    to {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes logo-animation-small-fill {
    from {
      fill: transparent;
    }
    to {
      stroke-dashoffset: 0;
      fill: #665a4f;
    }
  }
  
  @keyframes logo-animation-medium {
    from {
      fill: transparent;
    }
    to {
      stroke-dashoffset: 0;
      fill: #665a4f;
    }
  }
  @keyframes fill {
    0% {
      fill: transparent;
    }
  
    50% {
      stroke-dashoffset: 90%;
    }
  
    100% {
      stroke-dashoffset: 0;
      fill: #adca25;
    }
  }
  