* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html,
body {
  overflow-x: hidden;
  scroll-behavior: scroll;
}

.privacy_policy_container h5 {
  font-weight: 600;
}
.privacy_policy_container {
  position: relative;
  top: 100px;
}
